<template>
  <v-container>
    <qrcode-stream :camera="camera" @init="onInit" @decode="onDecode">
      <v-btn @click="switchCamera">
        <v-img
          src="https://cdn-icons-png.flaticon.com/512/964/964063.png?w=360"
          width="40"
        >
        </v-img>
      </v-btn>
    </qrcode-stream>
    <v-snackbar
      v-model="snackbar"
      :timeout="3000"
      :color="snackError ? 'red' : 'green'"
    >
      {{snackbarText}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from "vue-qrcode-reader";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      camera: "rear",
      noRearCamera: false,
      noFrontCamera: false,
      snackbar: false,
      snackbarText: "",
      snackError: false,
    };
  },
  props: {
    eventId: { type: String, required: true },
    invitational: { type: Boolean, required: true },
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture,
  },
  methods: {
    ...mapActions("attendee", ["checkIn"]),
    switchCamera() {
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      }
    },

    onDecode(QrString) {
      // https://wechamber.mx/member/credential/633f3b4d9a286cc23f08599a

      if (QrString.includes("wechamber.mx/member/credential/") && this.invitational) {
        let splitted = QrString.split("/");
        QrString = splitted[splitted.length - 1];
      }

      const data = this.checkIn({
        id: QrString,
        eventId: this.eventId,
        invitational: this.invitational,
      }).then((datos) => {
        if (datos.status === false) {
          this.snackbarText = datos.message;
          this.snackError = true;
          this.snackbar = true;
        }else{
          this.snackbarText = "Asistencia registrada con exito";
          this.snackError = false;
          this.snackbar = true;
        }
      }).catch((error) => {
        console.log(error);
      });
    },
  },
  
};
</script>

<style>
</style>