<template>
  <v-container>
    <v-hover v-slot="{ hover }">
      <v-card
        class="mx-auto event-card text-center"
        rounded="xl"
        max-width="344"
        :elevation="hover ? '8' : '4'"
        @click="dialog = true"
      >
        <v-img
          v-if="event.banner && event.banner != ''"
          :src="event.banner"
          :gradient="
            hover
              ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
              : ''
          "
          :height="180"
        >
        </v-img>
        <v-img
          v-else
          src="@/assets/images/shared/withoutbanner.png"
          :gradient="
            hover
              ? 'to top right, rgba(255,255,255,.33), rgba(196, 196, 196,.7)'
              : ''
          "
          :height="180"
        >
        </v-img>

        <v-card-title
          class="title font-weight-black d-inline-block pt-1"
          style="max-width: 100%"
        >
          EVENTO
        </v-card-title>

        <v-card-subtitle class="mb-2">
          <v-row dense>
            <v-col cols="12">
              <p class="mb-0 subtitle-1 font-weight-bold">
                {{ event.name }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-row justify="center"> INICIO </v-row>
              <v-row justify="center">
                {{ formatDate(event.dateConfiguration.startDate) }}
              </v-row>
            </v-col>
            <v-col cols="6" align-self="center">
              <v-row justify="center">
                <v-sheet
                  class="rounded-pill"
                  width="80%"
                  height="30"
                  style="
                    background: linear-gradient(to right, #2962ff, #448aff);
                  "
                  v-if="event.published == true"
                >
                  <p class="mt-1 white--text">
                    <v-icon color="white"> mdi-check-circle </v-icon>
                    Publicado
                  </p>
                </v-sheet>
                <v-sheet
                  class="rounded-pill"
                  width="60%"
                  height="30"
                  style="background: linear-gradient(to right,rgba(152, 0, 0, 255),rgba(203, 0, 2, 255));"
                  v-else
                >
                  <p class="mt-1 white--text">
                    <v-icon color="white"> mdi-close-circle </v-icon>
                    Oculto
                  </p>
                </v-sheet>
              </v-row>
            </v-col>
          </v-row>
        </v-card-subtitle>
      </v-card>
    </v-hover>
    <v-dialog v-model="dialog" v-if="dialog">
      <QrReader :eventId="event._id" :invitational="event.invitationalEvent" @close="closeDialog"></QrReader>
    </v-dialog>
    
  </v-container>
</template>
<script>
import QrReader from "./QrReader.vue";
export default {
  components: {
    QrReader,
  },
  props: {
    event: { type: Object, required: true },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    formatDate(date) {
      let [year, month, day] = date.substring(0, 10).split("-");
      return `${day}-${month}-${year}`;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>
<style scoped>
.event-card {
  transition: all 0.8s;
}

.event-card:hover {
  transform: scale(1.05);
}

.grayscale-disabled-element {
  filter: grayscale(1);
}
</style>