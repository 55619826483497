<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="10" class="text-center">
        <v-sheet color="accent rounded-xl pl-3">
          <v-text-field
            v-model="eventsData.name"
            solo
            dense
            placeholder="Buscar evento"
            hide-details="auto"
            class="rounded-search"
            clearable
            :disabled="loading"
            v-on:keyup.enter="fetchOrganizationEvents()"
            @click:clear="(eventsData.name = ''), fetchOrganizationEvents()"
          >
            <template v-slot:prepend>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon small v-on="on" :loading="loading">
                    <v-icon>fa fa-search</v-icon>
                  </v-btn>
                </template>
                Clic para buscar evento
              </v-tooltip>
            </template>
          </v-text-field>
        </v-sheet>
      </v-col>
    </v-row>
    <v-row v-for="event in organizationEvents" :key="event._id" class="my-10">
      <SimpleEventCard :event="event"></SimpleEventCard>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import SimpleEventCard from "../../components/dashboard/QrCode/SimpleEventCard.vue";
export default {
  data() {
    return {
      eventsData: {
        page: 0,
        perPage: 12,
        name: "",
      },
      organizationEvents: [],
      loading: false,
    };
  },
  components: {
    SimpleEventCard,
  },
  methods: {
    async fetchOrganizationEvents() {
      try {
        this.loading = true;
        const { data } = await this.axios({
          method: "GET",
          url: `/events/qr?page=${this.eventsData.page}&limit=${this.eventsData.perPage}&name=${this.eventsData.name}`,
        });

        this.organizationEvents = data.events;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.fetchOrganizationEvents();
    if (this.$vuetify.breakpoint.mdAndUp) {
      this.$router.push("/");
    }
  },
};
</script>

<style>
</style>